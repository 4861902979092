import React from "react"
import styled from "styled-components"
import Layout from "../components/Layout"
import FAQ from "../components/icons/BoatIcon"
import FAQBlock from "../components/FAQBlock"
import Faq from "react-faq-component"

const faq = ({ data }) => {
  const faqData = {
    title: "Our frequently asked questions",
    rows: [
      {
        title: (
          <div className="faqRow">
            <div className="faqIcon">
              <FAQ width="50px" />
            </div>
            <div className="faqText">
              <p>Is Dockside family friendly?</p>
            </div>
          </div>
        ),
        content:
          "Yes! Family members under the age of 21 must be accompanied by a parent or guardian. We also have highchairs available and offer a variety of seating options. Please clarify in your reservation.",
      },
      {
        title: (
          <div className="faqRow">
            <div className="faqIcon">
              <FAQ width="50px" />
            </div>
            <div className="faqText">
              <p>Do you have a kids menu?</p>
            </div>
          </div>
        ),
        content:
          "We don’t have a designated kids menu, but we have kid-friendly options such as chicken tenders and cheese pizza.",
      },
      {
        title: (
          <div className="faqRow">
            <div className="faqIcon">
              <FAQ width="50px" />
            </div>
            <div className="faqText">
              <p>Can I bring my dog?</p>
            </div>
          </div>
        ),
        content:
          "While we love pups, unfortunately, we are not able to welcome dogs since we serve food on all levels.",
      },
      {
        title: (
          <div className="faqRow">
            <div className="faqIcon">
              <FAQ width="50px" />
            </div>
            <div className="faqText">
              <p>Do I need a mask?</p>
            </div>
          </div>
        ),
        content:
          "Yes, every patron will need to wear a mask to enter Dockside. Masks can be removed only while seated at your table during your dining experience. ",
      },
      {
        title: (
          <div className="faqRow">
            <div className="faqIcon">
              <FAQ width="50px" />
            </div>
            <div className="faqText">
              <p>Is there a time limit?</p>
            </div>
          </div>
        ),
        content: "Yes, all dining experiences are limited to 2-hour seatings. ",
      },
      {
        title: (
          <div className="faqRow">
            <div className="faqIcon">
              <FAQ width="50px" />
            </div>
            <div className="faqText">
              <p>What happens if it rains? Is there indoor seating?</p>
            </div>
          </div>
        ),
        content:
          "We have a limited amount of seating available inside, so we cannot guarantee that we will be able to accommodate you if the weather takes a turn for the worse. However, most of our tables have umbrellas, and in the event of rain, your server will gladly provide to-go containers for your food. ",
      },
    ],
  }

  const iglooFaqData = {
    title: "Igloo FAQs",
    rows: [
      {
        title: (
          <div className="faqRow">
            <div className="faqIcon">
              <FAQ width="50px" />
            </div>
            <div className="faqText">
              <p>Is there a minimum spend in igloos?</p>
            </div>
          </div>
        ),
        content:
          "Yes, due to limited eating, sanitization, and increasingly high demand a $150.00 minimum spend is required for each igloo reservation",
      },

      {
        title: (
          <div className="faqRow">
            <div className="faqIcon">
              <FAQ width="50px" />
            </div>
            <div className="faqText">
              <p>Is there a time limit for igloo reservations?</p>
            </div>
          </div>
        ),
        content:
          "All igloo reservations are limited to 2.5 hours to provide a safe and sanitized environment for our guests. ",
      },

      {
        title: (
          <div className="faqRow">
            <div className="faqIcon">
              <FAQ width="50px" />
            </div>
            <div className="faqText">
              <p>How many people fit in an igloo?</p>
            </div>
          </div>
        ),
        content:
          "Igloos fit from 4 - 8 people, but not all igloos will fit 8. All igloos are first come, first serve, and finalized by the number of people in your party. No Igloo theme is guaranteed as there are many factors in a winter dining experience.",
      },

      {
        title: (
          <div className="faqRow">
            <div className="faqIcon">
              <FAQ width="50px" />
            </div>
            <div className="faqText">
              <p>Are igloos heated?</p>
            </div>
          </div>
        ),
        content:
          "Igloos come equipped with up to two heaters, but we recommend you still dress for an outdoor climate. Please do not adjust heater settings or tamper with power. Ask your server for assistance or a manager for help.",
      },

      {
        title: (
          <div className="faqRow">
            <div className="faqIcon">
              <FAQ width="50px" />
            </div>
            <div className="faqText">
              <p>Are children allowed in igloos?</p>
            </div>
          </div>
        ),
        content:
          "For safety, some decorations may be unsuitable for young children. Igloo lighting is limited. For clearer visibility of igloo elements, we suggest Igloo reservations that include children under the age of 13 are best at noon or 3:30 seating. No children may be in or around an igloo for any reason without a parent or guardian.",
      },

      {
        title: (
          <div className="faqRow">
            <div className="faqIcon">
              <FAQ width="50px" />
            </div>
            <div className="faqText">
              <p>What time slots are available for igloo reservations?</p>
            </div>
          </div>
        ),
        content:
          "Igloo bookings are available Sunday through Wednesday in the following time slots: 12 PM, 5 PM and 8 PM. Thursday through Saturday has the following slots: 12 PM, 3 PM, 6 PM, and 9 PM",
      },

      {
        title: (
          <div className="faqRow">
            <div className="faqIcon">
              <FAQ width="50px" />
            </div>
            <div className="faqText">
              <p>How can I reserve an igloo?</p>
            </div>
          </div>
        ),
        content: `To reserve an Igloo, please choose "Igloo" from the list of reservations. Requests for Igloo seating on regular outdoor reservations will not be granted. `,
      },

      {
        title: (
          <div className="faqRow">
            <div className="faqIcon">
              <FAQ width="50px" />
            </div>
            <div className="faqText">
              <p>When can I reserve an igloo?</p>
            </div>
          </div>
        ),
        content:
          "Reservations are available 7 days in advance for all igloos and regular indoor and outdoor seating.",
      },

      {
        title: (
          <div className="faqRow">
            <div className="faqIcon">
              <FAQ width="50px" />
            </div>
            <div className="faqText">
              <p>Are igloos a formal dine-in experience?</p>
            </div>
          </div>
        ),
        content: `Themed igloos are designed to provide a unique experience. The igloo dining style in all igloos is more casual than traditional. Ice Castle is the only igloo that offers a more formal dining experience.
Due to Covid-19, we are limiting the number of physical menus handed out. Each igloo has an easy to scan QR code available for viewing the menu and includes beautiful images of menu items, beers, and drinks.
`,
      },
    ],
  }

  return (
    <Layout pageTitle="FAQ">
      <Page>
        <ContentContainer className="container">
          <Faq data={faqData} />
        </ContentContainer>
        <ContentContainer className="container">
          <Faq data={iglooFaqData} />
        </ContentContainer>
      </Page>
    </Layout>
  )
}

export default faq

const Page = styled.section`
  padding: 3rem 1.5rem;
  background: #eeeeee;
  .row-content {
    color: #014e71 !important;
    padding: 0rem 1rem;
    /* color: blue!important; */
  }
  .faqRow {
    display: flex;
    align-items: center;
  }
  .faqIcon {
    margin-right: 10px;
  }
`

// the faq library doesn't play well with styled components. added css to container
const ContentContainer = styled.div`
  max-width: 800px;
  padding-bottom: 2rem;

  .styles_faq-row-wrapper__3Hsch {
    padding: 1rem;
  }

  .section__faq {
    background: #eee;
  }

  .styles_faq-row-wrapper__3Hsch h2 {
    text-align: center;
    font-size: 2rem;
    text-transform: uppercase;
  }

  #gatsby-focus-wrapper
    > main
    > section.section.section__faq
    > div
    > div
    > section.faq-title.styles_faq-row__2Rd2Y {
    justify-content: center;
  }

  .styles_faq-row-wrapper__3Hsch
    .styles_row-body__8wIE9
    .styles_faq-row__2Rd2Y
    .styles_row-title__2yqnt
    > div {
    width: 100%;
  }

  .styles_faq-row-wrapper__3Hsch
    .styles_row-body__8wIE9
    .styles_faq-row__2Rd2Y
    .styles_row-title__2yqnt.styles_expanded__2KFpr
    + .styles_row-content__TVd0Y.styles_animate__3cloa {
    padding-left: 63px;
  }
  .styles_faq-row-wrapper__3Hsch
    .styles_row-body__8wIE9
    .styles_faq-row__2Rd2Y
    .styles_row-title__2yqnt {
    padding-bottom: 0 !important;
  }

  .styles_faq-row-wrapper__3Hsch .styles_faq-row__2Rd2Y {
    padding-bottom: 1rem !important;
  }

  .styles_faq-row-wrapper__3Hsch
    .styles_row-body__8wIE9
    .styles_faq-row__2Rd2Y
    .styles_row-title__2yqnt.styles_expanded__2KFpr
    + .styles_row-content__TVd0Y.styles_animate__3cloa {
    color: $dark;
    font-weight: 400 !important;

    @media (min-width: 1024px) {
      padding-right: 6rem !important;
    }
  }

  .styles_faq-row-wrapper__3Hsch
    .styles_row-body__8wIE9
    .styles_faq-row__2Rd2Y
    .styles_row-title__2yqnt.styles_expanded__2KFpr
    + .styles_row-content__TVd0Y.styles_animate__3cloa {
    /* margin-top: -12px; */
  }
  .column__question p {
    padding-left: 2rem;
    @media (min-width: 768px) {
      padding-left: 0;
    }
  }
`

export const query = graphql`
  {
    siteMetaData {
      avatar {
        imageBaseUrl
      }
      city
      desc
      cover {
        imageBaseUrl
      }
      hours {
        fri {
          close
          open
        }
        mon {
          close
          open
        }
        sat {
          close
          open
        }
        sun {
          close
          open
        }
        thu {
          close
          open
        }
        tue {
          close
          open
        }
        wed {
          close
          open
        }
      }
      lastPricelistUpdate {
        sec
        usec
      }
      links {
        facebook
        instagram
        twitter
        youtube
        website
      }
      loc
      slug
      name
      phone
      state
      street
      zip
      bizID
    }
  }
`
